import ApiService from '../common/api.service'
import StorageService from '../common/storage.service'
import decrypt from '../common/decrypt'

class ProfileService {
  getAuthUser() {
    return ApiService.get('profile').then(response => response.data, error => Promise.reject(error))
  }

  updateProfile(data) {
    return ApiService.post('/update/user/info', data).then(response => {
      const user = decrypt(response.data.data)
      const existing = StorageService.getStorage('user')
      existing.name = user.name
      StorageService.setStorage('user', existing)
      return user
    })
  }

  // changePassword(user) {
  //   return ApiService.post('profile/change/password', {
  //       old_password:user.old_password,
  //       password: user.password,
  //       password_confirmation:user.password_confirmation,
  //     });
  // }

  // updateTwoFactorStatus(status){
  //   return ApiService.post('profile/update/two_factor_status', {
  //       two_factor_status:status,
  //     }).then(response => {
  //     var existing = StorageService.getStorage('user');
  //     existing['two_factor_status'] = status;
  //     StorageService.setStorage('user',existing)
  //     return response.data;
  //   });

  // }
}

export default new ProfileService()
