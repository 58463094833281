export default [
  {
    path: '/all/transactions',
    name: 'all-transactions',
    component: () => import('@/views/reports/AllTransection.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  // {
  //     path: '/transfer',
  //     name: 'transfer-report',
  //     component: () => import('@/views/reports/Transfer.vue'),
  //     meta: {
  //         action: 'API_B2B_USER',
  //         resource: 'AccessBothUser',
  //         auth: true,
  //     }
  // },
  // {
  //     path: '/reload',
  //     name: 'reload-report',
  //     component: () => import('@/views/reports/Recharge.vue'),
  //     meta: {
  //         action: 'API_B2B_USER',
  //         resource: 'AccessBothUser',
  //         auth: true,
  //     }
  // },
  {
    path: '/bill/payment',
    name: 'bill-payment',
    component: () => import('@/views/reports/BillPayment.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  // {
  //     path: '/pin/report',
  //     name: 'pin-report',
  //     component: () => import('@/views/reports/PinReport.vue'),
  //     meta: {
  //         action: 'API_B2B_USER',
  //         resource: 'AccessBothUser',
  //         auth: true,
  //     }
  // },
  // {
  //     path: '/debit/note',
  //     name: 'debit-note',
  //     component: () => import('@/views/reports/DebitNote.vue'),
  //     meta: {
  //         action: 'API_B2B_USER',
  //         resource: 'AccessBothUser',
  //         auth: true,
  //     }
  // },
  // {
  //     path: '/joinings/income',
  //     name: 'joinings-income',
  //     component: () => import('@/views/reports/JoiningIncomeInfo.vue'),
  //     meta: {
  //         action: 'API_B2B_USER',
  //         resource: 'AccessBothUser',
  //         auth: true,
  //     }
  // },
  {
    path: '/archive/transactions',
    name: 'archive-transactions',
    component: () => import('@/views/reports/AllArchiveTransection.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/wallet/statement',
    name: 'wallet-summary',
    component: () => import('@/views/reports/WalletSummary.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/summary/info',
    name: 'summary-info',
    component: () => import('@/views/reports/SummaryInfo.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
]
