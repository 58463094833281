export default [
  // {
  //   path: '/recharge',
  //   name: 'recharge',
  //   component: () => import('@/views/recharge/Recharge.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@/views/recharge/Transfer.vue'),
    meta: {
      action: 'B2B',
       resource: 'B2BUser',
      auth: true,
    },
  },
]
