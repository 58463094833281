 import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import ApiService from '../common/api.service'
import store from '../store'
import StorageService from '../common/storage.service'
import AuthService from '../services/auth.service'
// Routes
import pages from './routes/pages'
import dashboard from './routes/dashboard'
import recharge from './routes/recharge'
import user from './routes/user'
import userReport from './routes/user-report'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...pages,
    ...dashboard,
    ...user,
    ...userReport,
    ...recharge,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
ApiService.init()
// eslint-disable-next-line consistent-return
router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!store.getters.isAuthenticated) return next({ name: 'auth-login' })

      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }
    if (store.getters.isAuthenticated) {
      const date = new Date()
      if (StorageService.getStorage('expire_time') > date.getTime()) {
        if (store.getters.currentUser) {
          const date2 = new Date(store.getters.currentUser.two_factor_expires_at)
          const nowUtc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
          if (store.getters.currentUser.two_factor_expires_at != null && date2.getTime() < nowUtc.getTime()) {
            AuthService.restTwoFactorCode()
            // eslint-disable-next-line no-unused-vars
            store.dispatch('logout').then(r => next('/login'))
          }

          if (store.getters.currentUser.two_factor_expires_at != null && !to.name.includes('verify')) {
            next('/verify')
          } else if (store.getters.currentUser.is_new_user==1 && !to.name.includes('details') && !to.name.includes('verify')) {
            next('/details')
          } else if(store.getters.currentUser.two_factor_expires_at == null && to.name.includes('verify')) {
              next('/dashboard')
          }
        }
        next()
        // eslint-disable-next-line consistent-return
        return
      }
      // eslint-disable-next-line no-unused-vars
      store.dispatch('logout').then(r => next('/login'))

      // eslint-disable-next-line consistent-return
      return
    }
    next('/login')
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next('/dashboard')
      // eslint-disable-next-line consistent-return
      return
    }
    next()
  } else {
    next()
    return
  }
})

export default router
