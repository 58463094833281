export default [
  {
    path: '/profile',
    name: 'user-profile',
    component: () => import('@/views/user-settings/UserSettings.vue'),
    meta: {
      action: 'API_B2B_USER',
      resource: 'AccessBothUser',
      auth: true,
    },
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('@/views/user-settings/UserProfileEdit.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },
  // downline User*/
  {
    path: '/manage/all/users',
    name: 'downline-users',
    component: () => import('@/views/downline-user/DownlineUserList.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },
  {
    path: '/user/add',
    name: 'downline-user-add',
    component: () => import('@/views/downline-user/DowlineUserAdd.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },
  {
    path: '/user/edit',
    name: 'downline-users-edit',
    component: () => import('@/views/downline-user/DownlineUserEdit.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },
  // Discount Plan//
  {
    path: '/discount/plans',
    name: 'discount-plan',
    component: () => import('@/views/discount-plan/DiscountPlanList.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },
  {
    path: '/discount/plan/edit',
    name: 'discount-plan-edit',
    component: () => import('@/views/discount-plan/DiscountPlanEdit.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },
  {
    path: '/discount/plan/Add',
    name: 'discount-plan-add',
    component: () => import('@/views/discount-plan/DiscountPlanAdd.vue'),
    meta: {
      action: 'B2B',
      resource: 'B2BUser',
      auth: true,
    },
  },

]
