import CryptoJS from 'crypto-js'
import unserialize from './unserialize'
import { APP_KEY } from './config'
import StorageService from './storage.service'

const decrypt = encrypted => {
  const key = (StorageService.getStorage('anyenkey') != '') ? StorageService.getStorage('anyenkey') : APP_KEY
  const encryptedJson = JSON.parse(atob(encrypted))

  const decrypted = CryptoJS.AES.decrypt(encryptedJson.value, CryptoJS.enc.Base64.parse(key), {
    iv: CryptoJS.enc.Base64.parse(encryptedJson.iv),
  }).toString(CryptoJS.enc.Utf8)
  return unserialize(decrypted)
}

export default decrypt
