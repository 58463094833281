<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import StorageService from './common/storage.service'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {

  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      events: ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'],
      logoutTimer: '',
      vueAppClasses: [],
      expireTime: '',
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  async created() {
    const self = this
    this.$http.interceptors.response.use(undefined, err => new Promise((resolve, reject) => {
      if (err.response.status === 401 && err.config && !err.config.__isRetryRequest && self.$route.name != 'auth-login') {
        self.logOut()
      }
      throw err
    }))
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.restTimer)
    }, this)
    this.setTimer()
    window.addEventListener('unload', event => {
      const date = new Date()
      this.expireTime = (date.getTime() + 60 * 60 * 10)
      StorageService.setStorage('expire_time', this.expireTime)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.restTimer)
    }, this)
    this.restTimer()
  },
  methods: {
    logOut() {
      this.events.forEach(function (event) {
        window.removeEventListener(event, this.restTimer)
      }, this)
      this.restTimer()
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    setTimer() {
      const date = new Date()
      this.logoutTimer = setTimeout(this.logOutUser, 60 * 60 * 1000)
      this.expireTime = (date.getTime() + 60 * 60 * 1000)
      StorageService.setStorage('expire_time', this.expireTime)
    },
    logOutUser() {
      this.logOut()
    },
    restTimer() {
      clearTimeout(this.logoutTimer)
      this.setTimer()
    },
  },
}
</script>
