import SecureLS from 'secure-ls'
import ApiService from '../common/api.service'
import StorageService from '../common/storage.service'
import decrypt from '../common/decrypt'
import Csrf from '../common/csrf'
import Cookie from 'js-cookie'


const ls = new SecureLS({ encodingType: 'aes', isCompression: false })

class AuthService {
  // eslint-disable-next-line class-methods-use-this
  async login(loginId, pwd) {
    await Csrf.getCookie()
    return ApiService.login('auth/login', {
      loginid: loginId,
      password: pwd,
    })
      .then(response => {
        const data = decrypt(response.data.data)
        if (data) {
          StorageService.setStorage('loggedIn', true)
          StorageService.setStorage('user', data)
          StorageService.setStorage('anyenkey', data.key)
        }

        return data
      })
  }

  // eslint-disable-next-line class-methods-use-this
  async logout() {
    await Csrf.getCookie()
    ApiService.post('logout').then(response => Promise.resolve(response.data.data), error => Promise.reject(error))
    const expireTime = StorageService.getStorage('expire_time')
    ls.removeAll()
    Cookie.remove('anyenkey')
    StorageService.setStorage('expire_time', expireTime)
  }

  // eslint-disable-next-line class-methods-use-this
  registerationInfoGet() {
    return ApiService.query('auth/get/registration/info')
      .then(response => Promise.resolve(response.data.data), error => Promise.reject(error))
  }

  // eslint-disable-next-line class-methods-use-this
  async registerUser(data) {
    await Csrf.getCookie()
    return ApiService.post('auth/register/user', data)
      .then(response => Promise.resolve(response.data.data), error => Promise.reject(error))
  }

  // eslint-disable-next-line class-methods-use-this
  forgotPassword(email) {
    return ApiService.post('auth/reset-password', {
      email:email,
    })
      .then(response => {
        if (response.data.token) {
          StorageService.setStorage('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  // eslint-disable-next-line class-methods-use-this
  authCheck() {
    return ApiService.get('auth/check')
      .then(response => response.data.auth)
  }

  // eslint-disable-next-line class-methods-use-this
  verifyTwoFactorCode(data) {
    return ApiService.query('/vaildate/otp', data).then(response => {
      const existing = StorageService.getStorage('user')
      existing.two_factor_expires_at = null
      StorageService.setStorage('user', existing)
      return Promise.resolve(response)
    }, error => Promise.reject(error))
  }

  // eslint-disable-next-line class-methods-use-this
  restTwoFactorCode() {
    return ApiService.get('/auth/reset/code')
      .then(response => Promise.resolve(response.data), error => Promise.reject(error))
  }

  // eslint-disable-next-line class-methods-use-this
  resendTwoFactorCode(data) {
    return ApiService.post('/auth/resend/code', data)
      .then(response => {
        const existing = StorageService.getStorage('user')
        existing.two_factor_code = response.data.user.two_factor_code
        existing.two_factor_expires_at = response.data.user.two_factor_expires_at
        StorageService.setStorage('user', existing)
        return response.data
      })
  }

  // eslint-disable-next-line class-methods-use-this
  resetPassword(user) {
    return ApiService.post('/auth/reset/password', {
      token: user.user.token,
      email: user.user.email,
      password: user.user.password,
      password_confirmation: user.user.password_confirmation,
    })
      .then(response => {
        if (response.data.token) {
          StorageService.setStorage('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }
}

export default new AuthService()
