<template>

  <b-button-group>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :disabled="!pagination.prev_page_url"
      variant="outline-primary"
      @click.prevent="changePage(pagination.current_page - 1)"
    >
      Prev
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :disabled="!pagination.next_page_url"
      variant="outline-primary"
      @click.prevent="changePage(pagination.current_page + 1)"
    >
      Next
    </b-button>
  </b-button-group>
</template>
<script>
import { BButtonGroup, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButtonGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    offset: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      page: '',
    }
  },
  computed: {
    pagesNumber() {
      if (!this.pagination.to) {
        return []
      }
      let from = this.pagination.current_page - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (this.offset * 2)
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page
      }
      const pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    },
    totalPagesNumber() {
      const totalPagesArray = []
      for (let page = 1; page <= this.pagination.last_page; page++) {
        totalPagesArray.push(page)
      }
      this.page = this.pagination.current_page
      return totalPagesArray
    },
  },
  methods: {
    changePage(page) {
      this.pagination.current_page = page
      this.$emit('paginate')
    },
  },
}
</script>
